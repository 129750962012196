.gridParent {
  margin: 20px 10px 20px 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

.gridItem1 {
  grid-area: 1/1/2/2;
}

.gridParent > div:nth-child(1) {
  justify-self: center;
}

.gridItem2 {
  grid-area: 1/2/2/3;
}

.gridParent > div:nth-child(2) {
  justify-self: center;
}

.gridItem3 {
  grid-area: 1/3/2/4;
}

.gridParent > div:nth-child(3) {
  justify-self: center;
}


@media (min-width: 768px) {
  .gridParent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .gridItem1 {
    grid-area: 1/1/2/2;
  }
  .gridItem2 {
    grid-area: 2/1/3/2;
  }
  .gridItem3 {
    grid-area: 1/2/2/3;
  }
}

@media (min-width: 992px) {
  .gridParent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    margin-bottom: 0;
  }
  .gridItem1 {
    grid-area: 1/1/2/2;
  }
  .gridItem2 {
    grid-area: 2/1/3/2;
  }
  .gridItem3 {
    grid-area: 3/1/4/2;
  }
}

/* FlexBox Banner */

.placeholder {
  display: none;
}


.siteCaption {
  font-size: 2rem;
}

.siteLogo {
  flex-grow: 2;
  display: flex;
  flex-direction: row;
}

.siteImage {
  height: auto;
  width: 50px;
}

.siteIcons {
  display: none;
}

.siteCaption {
  align-self: center;
}



@media screen and (max-width: 567px) {

  .sm-150 {
    width: 150px;
  }
}